import { ConfigModifier } from './modifiers/modifiers.model';
import { OfferOrderFormTypeEnum } from '../../../offer-order-management/offer-order-form/offer-order-form-type';

export class Config {
  product: ConfigEntry<ProductProperty>[];
  offerOrder: ConfigEntry<OfferOrderProperty>[];
  offer: ConfigEntry<OfferProperty>[];
  standardLayout?: ConfigEntry<LayoutProperty>[];

  public clone(): Config {
    const cloned: Config = new Config();
    cloned.product = [].concat(this.product);
    cloned.offerOrder = [].concat(this.offerOrder);
    cloned.offer = [].concat(this.offer);
    if (this.standardLayout) { cloned.standardLayout = [].concat(this.standardLayout); }

    return cloned;
  }
}

export class AppliedConfig {
  product: ProductProperty[];
  offerOrder: OfferOrderProperty[];
  offer: OfferProperty[];
  standardLayout: LayoutProperty[];
}

export class ConfigEntry<T> {
  public readonly property: T;
  public readonly modes: ConfigMode[];
  public readonly modifiers?: ConfigModifier[];
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum OfferOrderProperty {
  chiffre = <any>'chiffre',
  attachment = <any>'attachment',
  createdDate = <any>'createdDate',
  tableEntry = <any>'tableEntry',
  topJob = <any>'topJob',
  jobBlitz = <any>'jobBlitz',
  jobBlitzRefresh = <any>'jobBlitzRefresh',
  kalaydo = <any>'kalaydo',
  itJobs = <any>'itJobs',
  tenhil = <any>'isTenhilBooked',
  duration = <any>'duration',
  eMail = <any>'eMail',
  applicationUrl = <any>'applicationUrl',
  homepage = <any>'homepage',
  alternativeCompany = <any>'alternativeCompany',
  chiffreContactDetails = <any>'chiffreContactDetails',
  comment = <any>'comment',
  searchTerm = <any>'searchTerm',
  publishEmail = <any>'publishEmail',
  customerApprovalEmail = <any>'customerApprovalEmail',
  publishingDate = <any> 'publishingDate',
  refreshOption = <any> 'refreshOption',
  refreshCancelDate = <any> 'refreshCancelDate',
  individualRefreshDates = <any> 'individualRefreshDates',
  deactivationDate = <any> 'deactivationDate',
  socialMedia = <any>'socialMedia',
  socialMediaProductId = <any>'socialMediaProductId',
  socialMediaAdditionalInfo = <any>'socialMediaAdditionalInfo',
  isSocialMediaMailSent = <any>'isSocialMediaMailSent',
  internalProductNameId = <any>'internalProductNameId'
}

export enum ProductProperty {
  product = <any>'product',
  companyNumber = <any>'companyNumber',
  companyName = <any>'companyName',
  companyLink = <any>'companyLink',
  number = <any>'number',
  offerOrderStatus = <any>'offerOrderStatus',
}

export enum OfferProperty {
  id = <any>'id',
  title = <any> 'title',
  titleAddition = <any>'titleAddition',
  location = <any> 'location', // This is called "location" even though the property is called "offerLocation"
  categoryGroupIds = <any> 'categoryGroupIds',
  searchKeywords = <any>'searchKeywords',
  startAndEndDates = <any>'startAndEndDates',
  statusAndWorkflowToDo = <any>'statusAndWorkflowToDo',
  clicks = <any>'clicks',
  externalId = <any>'externalId',
  externalLink = <any> 'externalLink',
  requirements = <any>'requirements',
  isExtended = <any>'isExtended',
}

export enum LayoutProperty {
  data = <any>'data',
  preview = <any>'preview',
}

export enum ConfigMode {
  create = <any> 'create',
  edit = <any> 'edit',
  correct = <any> 'correct',
  display = <any> 'display'
}

export function ConfigModeFromOfferFormType(formType: OfferOrderFormTypeEnum): ConfigMode {
  switch (formType) {
    case OfferOrderFormTypeEnum.Create: return ConfigMode.create;
    case OfferOrderFormTypeEnum.Edit: return ConfigMode.edit;
    case OfferOrderFormTypeEnum.Correct: return ConfigMode.correct;
  }
}

export const CREATE_EDIT_CORRECT_DISPLAY: ConfigMode[] = [ConfigMode.create, ConfigMode.edit, ConfigMode.correct, ConfigMode.display];
export const CORRECT_DISPLAY: ConfigMode[] = [ConfigMode.correct, ConfigMode.display];
export const CREATE_EDIT_CORRECT: ConfigMode[] = [ConfigMode.create, ConfigMode.edit, ConfigMode.correct];
export const DISPLAY: ConfigMode[] = [ConfigMode.display];
